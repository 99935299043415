.container.contact__container {
    width: 40%;
}

/* ========== FORM ========== */

.contact__form {
    display: none;
    flex-direction: column; 
    gap: 1rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    background: var(--color-bg-variant);
    border: 2.5px solid transparent;
    resize: none;
    color: var(--color-white);
    transition: all 400ms ease;
    font-size: 1rem;
}

input::placeholder {
    color:rgba(255, 255, 255, 0.68);
    font-size: 1rem;
}

textarea::placeholder {
    color:rgba(255, 255, 255, 0.68);
    font-size: 1rem;
}

input:hover, textarea:hover {
    transition: var(--transition);
    background: transparent;
    border-color: var(--color-primary-variant);
}

.success-message {
    color: var(--color-white);
    background: transparent;
    width: 100%;
    text-align: center;
    padding-top: 20vh;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    border-radius: 0.4rem;
}

.success-message h2 {
    padding-bottom: 20vh;
}

/* ========== END OF FORM ========== */



/* MEDIA QUERIES (MEDIUM DEVICES (Tablets)) */

@media screen and (max-width: 1024px) {

    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .phone_pic {
        width: 50%;
        margin: auto;
    }
}

/* MEDIA QUERIES (SMALL DEVICES (Phones)) */

@media screen and (max-width: 600px) {

    .container.contact__container{
        width: var(--container-width-sm); 
    }
}