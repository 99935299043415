.cta {
    display: flex;
    position: fixed;
    align-items: center; 
    justify-content: center; 
    background-color: transparent;
    top: 3%;
    left: 1%;
    height: auto;
    width: auto;
    border-radius: 2vh;
    border: 5px solid var(--color-primary);
    padding: 10px;
    gap: 10px;
}

.cube {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
}

.contact_image {
    width: 100%;
    height: 100%;
    border-radius: 40%;
}

/* ============= MEDIA QUERIES (MEDIUM DEVICES (Ipad)) =============*/
@media screen and (max-width: 1000px) {

    .cta {
        display: grid;
        top: 2.5%;
        left: 3%;
        border: 3px solid var(--color-primary);
        padding: 5px;
        gap: 5px;
    }
    
    .cube {
        width: 2.5rem;
        height: 2.5rem;
        margin: 3px;
    }

}

/* ============= MEDIA QUERIES (SMALL DEVICES (Phones)) =============*/
@media screen and (max-width: 600px) {
    
    .cta {
        display: grid;
        top: 1.5%;
        left: 3%;
        border: 3px solid var(--color-primary);
        padding: 0px;
        gap: 0px;
        height: auto;
        width: auto;
        padding-bottom: 7px;
    }
    
    .cube {
        width: 1.3rem;
        height: 1.3rem;
        margin: 3px;
    }
    
}

