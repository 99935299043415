nav {
    position: fixed;
    right: 3px;
    transform: translateY(-50%); 
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.5rem;
    z-index: 2;
    top: 50%;
    border-radius: 3rem;
    backdrop-filter: blur(5px);
}

.nav_buttons {
    background: transparent;
    padding: 0.9rem;
    border-radius: 3rem;
    display: flex;
    color: white;
    font-size: 1.8rem;
}

nav a:hover {
    color: var(--color-primary);
}

nav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
}

/* MEDIA QUERIES (medium DEVICES (ipads)) */
@media screen and (max-width: 1000px) {
    nav {
        padding: 0;
    }    

    .nav_buttons {
        padding: 1.4rem;
        font-size: 2rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES (Phones)) */
@media screen and (max-width: 600px) {

    .nav_buttons {
        padding: 0.7rem;
        font-size: 1.2rem;
        margin-right: 0rem;
    }
}