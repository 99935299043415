.TimeAndDate__container {
    position: absolute;
    top: 3%;
    right: 1%;
    height: auto;
    width: auto;
    text-align: center;
    background-color: transparent;
    border-radius: 2vh;
    color: var(--color-white);
    display: flex;
    align-items: center; 
    justify-content: center; 
    padding: 15px;
    border: 5px solid var(--color-primary);
    background: var(--color-bg);
    background-image: url('/src/assets/background/bg-texture.png');
}

.TimeAndDate__container h3 {
    margin: 0;
    width: 100%;
    font-weight: 600;
    font-size: 2.2vh;
}

/* MEDIA QUERIES (medium DEVICES (ipads)) */
@media screen and (max-width: 1000px) {

    .TimeAndDate__container {
        right: 3%;
        top: 2.5%;
        height: 4vh;
        padding: 15px;
    }

    .TimeAndDate__container h3 {
        font-size: 1.5vh;
    }
}

/* MEDIA QUERIES (SMALL DEVICES (Phones)) */
@media screen and (max-width: 600px) {

    .TimeAndDate__container {
        right: 3%;
        padding: 0px 5px;
        border: 3px solid var(--color-primary);
    }

    .TimeAndDate__container h3 {
        font-size: 1.4vh;
    }

}