/* ============= IMPORTS ============ */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --transition: all 400ms ease;
    --container-width-lg: 70%;
    --container-width-md: 75%;
    --container-width-sm: 74%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/background/bg-texture.png);
}

/* ============= GENERAL STYLES ============ */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,h2,h3,h4,h5 {
    font-weight: 500;
}

h1 {
    font-size: 7.5vh;
}

h4 {
    text-align: center;
    margin-bottom: 2rem;
}

section {
    margin-top: 5rem;
}

section > h3, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h3 {      
    color: var(--color-primary);
    margin-bottom: 3rem;
    text-decoration: underline;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    color: var(--color-white);
    border-color: transparent;
    background: #272829;
}

.btn-primary {
    background: transparent;
    color: var(--color-primary);
    transition: var(--transition);
}  

.btn-primary:hover {
    background: #004225;
    color: var(--color-white);
}  

/* ============= MEDIA QUERIES (MEDIUM DEVICES (Tablets)) =============*/
@media screen and (max-width: 1024px){

    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }

    h1 {
        font-size: 7vh; 
    }

    h2 {
        font-size: 3.5vh; 
    }

    h3 {
        font-size: 2.5vh; 
    }

    h4 {
        font-size: 2vh; 
    }

    h5 {
        font-size: 1.5vh; 
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES (Phones)) =============*/
@media screen and (max-width: 600px) {

    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }

    section > h3 {
        margin: 0 0 4vh 0;
    }

    h1 {
        font-size: 5vh; 
    }

    h2 {
        font-size: 2.5vh; 
    }

    h3 {
        font-size: 2vh; 
    }

    h4 {
        font-size: 1.5vh; 
    }

    h5 {
        font-size: 1.3vh; 
    }

    .btn {
        padding: 0.1rem;
        font-size: 1.5vh;
        margin-right: 0.2rem;
        background: #272829;
        color: var(--color-primary);
    }
    
    .btn-primary {
        background: #004225;
        color: var(--color-primary);
    }  

}

