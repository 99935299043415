header {
    height: 100%;
    padding-top: 4%;
}

.header__container {
    text-align: center;
    height: 100%;
}

.header_job {
    color: #f39c12;
    margin-top: 3%;
}

.me_wraper {
    display: flex;
    height: 60%;
    margin-top: 8%;
    padding-bottom: 10%;
}

.me_content_wraper {
    height: 100%;
    width: 70%;
    max-width: 70%;
    font-size: 2.4vh; 
    text-align: left;
    font-family: 'Shantell Sans', cursive;
    margin: auto;
}

.me_pic_wraper {
    height: 100%;
    width: 30%;
    max-width: 30%;
}

.me_pic_wraper img {
    width: 27vh;
    height: 27vh;

    border-radius: 200px 200px 200px 200px;
    -moz-border-radius: 200px 200px 200px 200px;
    -webkit-border-radius: 200px 200px 200px 200px;

    -webkit-box-shadow: 0px 0px 34px var(--color-primary);
    -moz-box-shadow: 0px 0px 34px 6px var(--color-primary);
    box-shadow: 0px 0px 34px 6px var(--color-primary);

    outline: 10px double var(--color-primary);
    background: var(--color-bg);
    background-image: url('/src/assets/background/bg-texture.png');
}

@media screen and (max-width: 1000px) {

    header {
        padding-top: 20%;
    }

    .me_wraper {
        margin-top: 12%;
        padding-bottom: 0%;
    }

    .me_content_wraper {
        font-size: 1.5vh; 
        min-width: 50%;
        max-width: 50%;
        margin-top: 5%;
        margin-left: 5%;
    }

    .me_pic_wraper {
        margin-top: 10%;
        width: 50%;
        max-width: 50%;
        height: 50%;
    }
    
    .me_pic_wraper img {
        width: 12.5vh;
        height: 12.5vh;
    }
}

@media screen and (max-width: 600px) {

    header {
        padding-top: 20%;
    }

    .me_wraper {
        margin-top: 12%;
        padding-bottom: 0%;
    }

    .me_content_wraper {
        font-size: 1.2vh; 
        min-width: 50%;
        max-width: 50%;
        margin-top: 5%;
    }

    .me_pic_wraper {
        margin-top: 10%;
        width: 50%;
        max-width: 50%;
        height: 50%;
    }
    
    .me_pic_wraper img {
        width: 12.5vh;
        height: 12.5vh;
    }
}