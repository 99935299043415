.footer {
    padding: 0.1rem 0;
    text-align: center;
    border-top: 3px solid var(--color-primary);
}

.footer__logo {
    margin-top: 5rem;
    display: inline-block;
    /* border-bottom: 1px solid white; */
}

.logoIMG {
    width: 20vh;
}

.footer__copyright {
    margin-top: 5rem;
    margin-bottom: 3rem;
    color: var(--color-primary);
    font-size: 170%;
}

/* MEDIA QUERIES (SMALL DEVICES (Phones)) */

@media screen and (max-width: 600px) {
    
    .footer__copyright {
        margin-bottom: 2rem;
        font-size: 3vh;
    }
}